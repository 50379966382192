



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_SCOPE_OF_FUNCTIONS, GET_SCOPE_OF_FUNCTIONS } from '../store';
import { ConservatorshipDetails } from '../../conservatorship/types';
import { ScopeOfFunctions } from '../types';
import { ApiResponse } from '@/components/types';
import CardRow from '@/components/CardRow.vue';

const ScopeOfFunctionsChangeDialog = () => import('./ScopeOfFunctionsChangeDialog.vue');

const Conservatorship = namespace('conservatorshipToScopeOfFunctions');

@Component({
  components: {
    ScopeOfFunctionsChangeDialog,
    CardRow
  }
})
export default class ScopeOfFunctionsPlugin extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @Conservatorship.Getter(GET_SCOPE_OF_FUNCTIONS) getScopeOfFunctionsByConservatorship!: (conservatorship_id: string) => ScopeOfFunctions[];
  @Conservatorship.Action(FETCH_SCOPE_OF_FUNCTIONS) fetchScopeOfFunctions!: (conservatorship_id: string) => Promise<ApiResponse>;

  get scopesOfFunctions() {
    return this.getScopeOfFunctionsByConservatorship(this.conservatorship.id);
  }

  @Watch('conservatorship', { immediate: true })
  async onConservatorshipChange() {
    await this.fetchScopeOfFunctions(this.conservatorship.id);
  }
}
